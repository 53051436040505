body > article {

  header {
    h1 {
      margin-bottom: 0;
    }

    h2 {
      margin: 0;
    }
    
    time, span[rel="author"] {
      font-size: 0.66em;
      color: #333;
    }
  }

  ul.posts {
    color: $dark-text;
    list-style: none;
    padding: 0;
    margin: 0;

    h3 {
      color: $secondary-color;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    li {
      > a {

        display: block;

        &:after {
          transform-origin: left center;
          transition: 0.5s all cubic-bezier(0.5, -0.2, 0.25, 1.2);

          content: ' ';
          display: block;
          width: 3rem;
          height: 5px;
          background-color: $secondary-color;
        }

        &:hover:after, &:focus:after {
          transform: scaleX(3);
        }
      }
    }
  }
}
