// Colors

$light-text: #fff;
$dark-text: #000;

$primary-color-dark:   #1976D2;
$primary-color:        #2196F3;
$primary-color-light:  #BBDEFB;
$primary-color-text:   #FFFFFF;
$secondary-color:      #2196F3;
$primary-text-color:   #212121;
$secondary-text-color: #757575;
$divider-color:        #BDBDBD;
